import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import type { NextComponentType, NextPageContext } from 'next';
import { type AppInitialProps, type AppProps } from 'next/app';
import { type NextRouter, useRouter } from 'next/router';
import { Growler } from '@gr/ui';
import { type AppContextType } from 'next/dist/shared/lib/utils';
import Head from 'next/head';
import { CookiesProvider, useCookies } from 'react-cookie';
import { getSession } from '../utils/getSession';
import { Path } from '../components/Navbar/constants/paths';

import 'moment/locale/pl';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/es';

import { LayoutWrapper } from '../layouts/LayoutWrapper';
import { type Layout } from '../types/layouts';

import { SessionProvider } from '../providers/SessionProvider';
import { ApiProvider } from '../providers/ApiProvider';
import { ThemeContextProvider } from '../components/ThemeContext/ThemeContext';
import { ThemeProviderWrapper } from '../components/ThemeProviderWrapper/ThemeProviderWrapper';

import 'reset-css';
import '@gr/ui/lib/style.css';
import '../styles/global.scss';
import { availableLanguages, defaultLocale, LANGUAGE_COOKIE } from '../constants/languages';
import { LocaleProvider } from '../providers/LocaleProvider';

export async function redirectUser(ctx: NextPageContext, router: NextRouter, location: string): Promise<void> {
    if (ctx.req) {
        ctx.res.writeHead(302, { Location: location });
        ctx.res.end();
    } else {
        await router.push('/login');
    }
}

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
    const [messages, setMessages] = useState({});
    const [preloading, setPreloading] = useState(true);
    const router = useRouter();
    const { pathname } = router;
    const routerLocale = router.locale;
    const [userLocale, setUserLocale] = useState<string>(null);
    const [downloadedTranslations, setDownloadedTranslations] = useState(false);
    const [cookies, setCookie] = useCookies([LANGUAGE_COOKIE]);

    React.useEffect(() => {
        const fetchTranslationMessages = async (): Promise<void> => {
            try {
                if (userLocale !== null) {
                    const response = await fetch(`/locales/${userLocale}.json`);
                    const data = await response.json();
                    setDownloadedTranslations(true);
                    setMessages(data);
                }
            } catch (error) {
                setMessages({});
            }
            setPreloading(false);
        };

        fetchTranslationMessages();
    }, [userLocale]);

    React.useEffect(() => {
        const style = document.getElementById('server-side-styles');

        if (style) {
            style.parentNode.removeChild(style);
        }
    }, []);

    React.useEffect(() => {
        async function fetchUserLocale(): Promise<void> {
            let locale = defaultLocale;

            try {
                if (routerLocale === defaultLocale) {
                    const storedLanguage = cookies[LANGUAGE_COOKIE];
                    const session = getSession(pageProps.req);

                    if (availableLanguages.includes(storedLanguage)) {
                        locale = storedLanguage;
                    } else if (session !== null) {
                        const response = await fetch('/api/users/locale');

                        if (response.ok) {
                            const parsedResponse = await response.json();
                            const downloadedLocale = parsedResponse?.locale;
                            if (availableLanguages.includes(downloadedLocale)) {
                                locale = downloadedLocale;
                                setCookie(LANGUAGE_COOKIE, downloadedLocale, { path: '/' });
                            }
                        }
                    }
                } else {
                    locale = routerLocale;
                }
            }
            finally {
                setUserLocale(locale);
            }
        }
        fetchUserLocale();
    }, [cookies, setCookie, pageProps.req, routerLocale]);

    function pageTitle() {
        switch (pathname) {
            case '/login': {
                return messages['LoginForm.PageTitle'];
            }
            default: {
                return messages['Shared.Title'];
            }
        }
    }

    return (
        downloadedTranslations && (
            <LocaleProvider>
                <CookiesProvider>
                    <SessionProvider>
                        <ApiProvider>
                            <ThemeContextProvider>
                                <ThemeProviderWrapper>
                                    <IntlProvider
                                        locale={userLocale}
                                        defaultLocale={defaultLocale}
                                        messages={messages}
                                        defaultRichTextElements={{
                                            br: () => <br/>,
                                        }}
                                    >
                                        {preloading ? null : (
                                            <LayoutWrapper layout={(Component as NextComponentType & { layout: Layout }).layout}>
                                                <Head>
                                                    <title>{pageTitle()}</title>
                                                </Head>
                                                <Growler.Container/>
                                                <Component {...pageProps}/>
                                            </LayoutWrapper>
                                        )}
                                    </IntlProvider>
                                </ThemeProviderWrapper>
                            </ThemeContextProvider>
                        </ApiProvider>
                    </SessionProvider>
                </CookiesProvider>
            </LocaleProvider>
        )
    );
}

MyApp.getInitialProps = async ({ Component, ctx, router }: AppContextType): Promise<AppInitialProps> => {
    const session = getSession(ctx.req);

    let pageProps = {};

    if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
    }

    if (
        (session === undefined || session === null) &&
    !ctx.pathname.match(
        /login|activation-thank-you|confirmation|forgot-password|saml|auth|migration-welcome|migration-thank-you|material|resources|404/,
    )
    ) {
        await redirectUser(ctx, router, '/login');
    }

    if (ctx.pathname === Path.FillPersonalDetails && session?.personalDetailsFilled !== 'no') {
        await redirectUser(ctx, router, '/');
    }

    if (ctx.pathname !== Path.FillPersonalDetails && session?.personalDetailsFilled === 'no') {
        await redirectUser(ctx, router, Path.FillPersonalDetails);
    }

    if (ctx.pathname.match(/arp\/.+/) && session?.assignedToRecurring === 'no') {
        await redirectUser(ctx, router, Path.ARP);
    }

    if (ctx.pathname.match(/abp\/.+/) && session?.assignedToBounty === 'no') {
        await redirectUser(ctx, router, Path.ABP);
    }

    return { pageProps } as AppProps;
};

export default MyApp;
