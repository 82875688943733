export const languages = {
    en: 'Languages.English',
    de: 'Languages.German',
    es: 'Languages.Spanish',
    pl: 'Languages.Polish',
    ru: 'Languages.Russian',
    it: 'Languages.Italian',
    fr: 'Languages.French',
    pt: 'Languages.Portugal',
};

export const availableLanguages = ['en', 'pl', 'de', 'es', 'it'];
export const defaultLocale = 'en';
export const LANGUAGE_COOKIE = 'language';
