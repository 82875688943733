import React from 'react';
import Link from 'next/link';
import { Path } from './constants/paths';
import styles from './styles/Navbar.module.scss';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import useSession from '../../hooks/useSession';

export function NavbarLinks(): JSX.Element {
    const router = useRouter();
    const { session } = useSession();

    const isPageActive = (path: Path): string => {
        if (path === '/') {
            return router.pathname === '/' ? styles.active : '';
        } else {
            const expression = `${path}.*`;
            const regexp = new RegExp(expression, 'g');

            return regexp.test(router.pathname) ? styles.active : '';
        }
    };

    return (
        <div className={styles.navigationLinks}>
            <Link href={Path.Dashboard} className={`${styles.navigationLink} ${isPageActive(Path.Dashboard)}`}>
                <FormattedMessage id="Navbar.Links.Dashboard"/>
            </Link>
            {session?.isAgency === 'yes' ? null : (
                <Link href={Path.ABP} className={`${styles.navigationLink} ${isPageActive(Path.ABP)}`}>
                    <FormattedMessage id="Navbar.Links.AbpProgram"/>
                </Link>
            )}
            <Link href={Path.ARP} className={`${styles.navigationLink} ${isPageActive(Path.ARP)}`}>
                <FormattedMessage id="Navbar.Links.IapProgram"/>
            </Link>
        </div>
    );
}
