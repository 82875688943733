import React from 'react';
import styles from './styles/Navbar.module.scss';
import { Popper } from '@gr/ui';
import { ClickAwayListener } from '../../hooks/ClickAwayListener';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import { HamburgerIcon } from '../../icons/HamburgerIcon';
import { OffIcon } from '../../icons/OffIcon';
import { Path } from './constants/paths';
import { useRouter } from 'next/router';
import useSession from '../../hooks/useSession';

export function NavbarMobile(): JSX.Element {
    const [isNavbarHamburgerOpen, setIsNavbarHamburgerOpen] = React.useState<boolean>(null);
    const navbarHamburgerContainer = React.useRef(null);
    const router = useRouter();
    const { session, signOut } = useSession();

    const handleHelperClick = React.useCallback((): void => {
        setIsNavbarHamburgerOpen((prev) => !prev);
    }, []);

    const handleHelperClickAway = React.useCallback((): void => {
        setIsNavbarHamburgerOpen(false);
    }, []);

    const hamburgerOpenClass = isNavbarHamburgerOpen ? styles.open : '';
    const isPageActive = (path: Path): string => (path === router.pathname ? styles.mobileActive : '');

    return (
        <React.Fragment>
            <div
                onClick={handleHelperClick}
                role="button"
                tabIndex={0}
                className={`${styles.navigationControlsHamburger} ${hamburgerOpenClass}`}
                ref={navbarHamburgerContainer}
            >
                <HamburgerIcon/>
            </div>
            <ClickAwayListener clickAwayFunction={handleHelperClickAway}>
                <Popper
                    dropVertical="bottom"
                    dropHorizontal="left"
                    isOpen={isNavbarHamburgerOpen}
                    anchorEl={navbarHamburgerContainer.current}
                    className={`${styles.navigationControlsPopper} ${styles.navigationControlsPopperArrowHamburger}`}
                >
                    <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.Dashboard)}`}>
                        <Link href={Path.Dashboard}>
                            <FormattedMessage id="Navbar.Links.Dashboard"/>
                        </Link>
                    </div>
                    {session?.isAgency === 'yes' ? null : (
                        <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.ABP)}`}>
                            <Link href={Path.ABP}>
                                <FormattedMessage id="Navbar.Links.AbpProgram"/>
                            </Link>
                        </div>
                    )}
                    <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.ARP)}`}>
                        <Link href={Path.ARP}>
                            <FormattedMessage id="Navbar.Links.IapProgram"/>
                        </Link>
                    </div>
                    <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.HelpCenter)}`}>
                        <Link href={Path.HelpCenter}>
                            <FormattedMessage id="Navbar.Helper.HelpCenter"/>
                        </Link>
                    </div>
                    <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.Library)}`}>
                        <Link href={Path.Library}>
                            <FormattedMessage id="Navbar.Helper.Library"/>
                        </Link>
                    </div>
                    <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.MyInquiries)}`}>
                        <Link href={Path.MyInquiries}>
                            <FormattedMessage id="Navbar.Helper.MyInquiries"/>
                        </Link>
                    </div>
                    <div className={`${styles.navigationControlsPopperHelperLink} ${isPageActive(Path.MyAccount)}`}>
                        <Link href={Path.MyAccount}>
                            <FormattedMessage id="Navbar.Account.MyAccount"/>
                        </Link>
                    </div>
                    <div className={styles.navigationControlsPopperDivider}/>
                    <div className={styles.navigationControlsPopperSignOutLink}>
                        <button type="button" onClick={() => signOut()}>
                            <FormattedMessage id="Navbar.Account.SignOut"/>
                        </button>
                        <OffIcon/>
                    </div>
                </Popper>
            </ClickAwayListener>
        </React.Fragment>
    );
}
