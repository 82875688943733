export enum Path {
    Dashboard = '/',
    ABP = '/abp',
    ARP = '/arp',
    HelpCenter = '/help-center',
    Library = '/library',
    MyInquiries = '/my-inquiries',
    MyAccount = '/account',
    Affiliates = 'https://app.getresponse.com/affiliates-connect/apc',
    FillPersonalDetails = '/fill-personal-details',
}
